var site = site || {};

site.changeLocale = function (country, lang) {
  var postfixes = {};
  postfixes[lang] = lang;
  postfixes[country] = country.toLowerCase();
  var init_cookie = generic.cookie('LOCALE');
  var locale_cookie = lang + '_' + country.toUpperCase();

  var path = '';
  if (generic.cookie('LOCALE')) {
    var localeParts = generic.cookie('LOCALE').toLowerCase().split('_');
    path = window.location.pathname.replace(/^\/[a-z]{2}\/[a-z]{2}/, "");
  }
  document.cookie = 'LOCALE=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  generic.cookie('LOCALE', locale_cookie, {path : '/'});

  // make use of Drupal settings to determine path
  if ( Drupal.settings.elc_common != undefined && (lang + '-e-' + country.toUpperCase() + '_url' in Drupal.settings.elc_common) && path != "/") {
    path = Drupal.settings.elc_common[lang + '-e-' + country.toUpperCase() + '_url'];
  }

  if (path !== "/") {
    path = window.location.pathname.replace(/^\/[a-z]{2}\/[a-z]{2}/, "");
  }
  path += window.location.search + window.location.hash;

  var url = window.location.protocol + '//' + window.location.host + '/' + postfixes[country] + '/' + postfixes[lang] + path;

  if (location.href != url) {
    location.href = url;
  }
};

jQuery(function(){
  var cookie_val = generic.cookie('LOCALE');
  var cookie_lang = [];
  var site_locales = [
    'fr_BE',
    'nl_BE',
  ];
  if (cookie_val && site_locales.indexOf(cookie_val) > -1) {
      cookie_lang = cookie_val.split('_') ;
  }else {
      cookie_lang[0] = 'nl';
  }

  jQuery('.js-switch-lang-link').bind('click', function(event) {
    event.preventDefault();
    var lang = (jQuery(this).attr('data-lang') == 'nl') ? 'nl' : (jQuery(this).attr('data-lang') == 'fr') ? 'fr' : 'en';
    return site.changeLocale('BE',lang);
  });
  jQuery('.switch-lang-link').bind('click', function(event) {
    event.preventDefault();
    var lang = (jQuery(this).attr('data-mp-lang') == 'nl') ? 'nl' : (jQuery(this).attr('data-mp-lang') == 'fr') ? 'fr' : 'en';
    return site.changeLocale('BE',lang);
  });
});
